// ID-113
body {
  text-rendering: optimizeLegibility;

  font-feature-settings: "kern", "liga"; /* standard */
  font-variant-ligatures: common-ligatures;
  font-kerning: normal;
}

.link-decoration(@base: underline);

a {
  .transition(~"all 0.3s linear"); // Double decl for Safari
  .transition(~"color 0.3s linear, text-decoration-color 0.15s linear");
}

a {
  .new-window-link, .insecure-link {
    .fas();
    // Partial copy of .fa-icon() from _mixins.less
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-size: @font-size-small;
    padding-left: 3px;
    margin-right: 2px;
    .transition(color 0.3s linear);
  }

  .new-window-link {
    color: @gray-light;

    &:before {
      content: @fa-var-external-link-alt;
    }
  }

  .insecure-link {
    color: @brand-danger;

    &:before {
      content: @fa-var-unlock-alt;
    }
  }

  &:hover {
    .new-window-link, .insecure-link {
      color: @link-color;
    }
  }

  &[href] {
    @media print {
      &:after {
        content: "";
      }

      &.print-expand:after {
        content: " (" attr(href) ")";
      }
    }
  }
}

abbr[title] {
  @media print {
    &:after {
      content: "";
    }

    &.print-expand:after {
      content: " (" attr(title) ")";
    }
  }
}

// ID-432
p:last-child {
  margin-bottom: 0;
}

.lead {
  font-weight: 700;
  font-size: (@font-size-base * 1.15);
}

// ID-105 Reduce heading sizes on XS
h1, .h1 { font-size: (@font-size-h1 * @font-scale-xs); }
h2, .h2 { font-size: (@font-size-h2 * @font-scale-xs); }
h3, .h3 { font-size: (@font-size-h3 * @font-scale-xs); }
h4, .h4 { font-size: (@font-size-h4 * @font-scale-xs); }
h5, .h5 { font-size: (@font-size-h5 * @font-scale-xs); }
h6, .h6 { font-size: (@font-size-h6 * @font-scale-xs); }

@media (min-width: @grid-float-breakpoint) {
  h1, .h1 { font-size: @font-size-h1; }
  h2, .h2 { font-size: @font-size-h2; }
  h3, .h3 { font-size: @font-size-h3; }
  h4, .h4 { font-size: @font-size-h4; }
  h5, .h5 { font-size: @font-size-h5; }
  h6, .h6 { font-size: @font-size-h6; }
}

// ID-105 Reduce spacing around major headings on XS
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: (@line-height-computed / 2);
}

@media (min-width: @grid-float-breakpoint) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: @line-height-computed;
  }
}

h1 {
  padding-bottom: (@line-height-computed / 2);
  border-bottom: 1px solid @id7-subtle-line-colour;

  @media print {
    border: none;
    padding-bottom: 0;
    margin-bottom: (@line-height-computed / 2);
  }
}

h6, .h6 {
  font-weight: @id7-h6-font-weight;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  &:first-child {
    margin-top: 0;
  }
}

.id7-page-title {
  margin-top: @grid-gutter-width;

  h1 {
    margin-bottom: 0;
  }
}

.bordered {
  border-bottom: 1px solid @id7-subtle-line-colour;
}

blockquote.quotes {
  border-left: none;

  &::before {
    position: absolute;
    content: '\201C';
    color: @id7-brand-gray;
    margin-left: -24px;
    margin-top: -10px;
    font-size: 4em;
    line-height: 1em;
  }

  &.pull-right, &.blockquote-reverse {
    border-right: none;

    &::before {
      margin-left: 4px;
      content: '\201D';
    }
  }

  @media print {
    border: none;
  }
}

// ID-127
ul, ol {
  padding-left: 30px;

  li {
    margin-bottom: 6px;
  }

  ul, ol {
    margin-top: 6px;
  }
}

ul {
  list-style-type: square;
}

ul ul, ol ul {
  list-style-type: circle;
}

ol ol ul, ol ul ul, ul ol ul, ul ul ul {
  list-style-type: disc;
}

ol ol ol ul, ol ol ul ul, ol ul ol ul, ol ul ul ul,
ul ol ol ul, ul ol ul ul, ul ul ol ul, ul ul ul ul {
  list-style-type: square;
}

label {
  color: #555;
}

abbr[title] {
  border-bottom: none;
  text-decoration-color: #777;
  text-decoration-style: dotted;
}
