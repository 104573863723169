// If tables are wider than the content, just scroll the table, not the whole page
.id7-wide-table-wrapper-container {
  margin-bottom: @line-height-computed;

  > .table-responsive {
    > table {
      margin-bottom: 0;
    }
  }
}

.id7-table-wrapper-popout {
  .small();
}

.id7-wide-table-popout-modal {
  .modal-dialog {
    max-width: 90%;
    width: auto;
  }

  .modal-body {
    max-height: 80vh;
    overflow: auto;

    > table {
      margin: 0;
    }
  }
}