.boxstyle_ { // legacy
  .make-sm-boxstyle();
}

.boxstyle-xs {
  .make-xs-boxstyle();
}

.boxstyle-sm {
  .make-sm-boxstyle();
}

.boxstyle-md {
  .make-md-boxstyle();
}

.boxstyle-lg {
  .make-lg-boxstyle();
}