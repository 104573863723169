body {
  width: 100%;
  min-height: 100vh;
}

.id7-fixed-width-container {
  .container-fixed(0);

  background-position: left top;
  background-repeat: no-repeat;

  > a.sr-only-focusable { // "Skip to main content" link
    top: 0px;
    left: @grid-gutter-width;
    text-shadow: 0 -1px 0 rgba(0,0,0,.2);
    z-index: 100;

    &:focus, &:active {
      position: absolute;
    }

    &, &:link, &:visited, &:hover, &:focus, &:active {
      color: white;
    }
  }

  // Stretch the content down to the footer, using Flexbox
  @media screen and (min-width: @grid-float-breakpoint) {
    html.flexbox & {
      height: 100vh; // Screw you IE ID-163
      display: flex;
      flex-direction: column;

      .id7-main-content-area {
        flex: 1 0 auto;
      }

      .id7-page-header, .id7-page-footer {
        flex-shrink: 0;
      }
    }
  }

  @media screen and (min-width: @screen-sm-min) {
    width: var(--w-sys-containerWidth);
  }

  position: relative;
  z-index: 1;

  @media screen and (min-width: @grid-float-breakpoint) {
    &.shadowed {
      box-shadow: 0 0 25px rgba(0, 0, 0, .1);
    }
  }
}

@media screen and (min-width: @grid-float-breakpoint) {
  .id7-left-border {
    background-position: right top;
    background-repeat: no-repeat;

    .bordered & {
      border-right: 1px solid #808080;
    }
  }

  .id7-right-border {
    background-position: left top;
    background-repeat: no-repeat;

    .bordered & {
      border-left: 1px solid #808080;
    }
  }
}

@media screen and (min-width: @grid-float-breakpoint) {
  .id7-left-border, .id7-right-border {
    position: fixed;
    top: 0px;
    width: 50%;
    height: 100%;
    z-index: 0;
  }

  .id7-left-border {
    left: 0;
    transform: translateX(calc(var(--w-sys-containerWidth) / -2));
  }

  .id7-right-border {
    right: 0;
    transform: translateX(calc(var(--w-sys-containerWidth) / 2));
  }
}

// @step is the number of x-pixels to create a step at
.make-large-screen-border-image-sizes(@step: 160; @max-container-width: @container-large-desktop) {
  .border-image-size-above(@width, @height) when (@width < 3360px) {
    @max-width: (@width + @step);
    @max-height: (@height + ((@height / @width) * @step));
    @bg-width: ((@max-width - @container-large-desktop) / 2);
    @bg-height: ((@id7-border-image-height / @id7-border-image-width) * @bg-width);

    @media screen and (min-width: (@width + 1)), screen and (min-height: (@height + 1)) {
      background-size: @bg-width @bg-height;
    }

    .border-image-size-above(@max-width, @max-height);
  }
  .border-image-size-above(@width, @height) when (@width >= 3360px) { // Terminal
    @media screen and (min-width: (@width + 1)), screen and (min-height: (@height + 1)) {
      background-size: cover;
    }
  }

  @max-border-image-screen-width: ((@id7-border-image-width * 2) + @max-container-width); // 1920px by default
  @max-border-image-screen-height: @id7-border-image-height; // 1200px

  // Kickstart
  .border-image-size-above(@max-border-image-screen-width, @max-border-image-screen-height);
}

.make-border-images() when not (@support-xl-viewport) {
  .id7-left-border, .id7-right-border {
    .make-large-screen-border-image-sizes();
  }
}
.make-border-images() when (@support-xl-viewport) and not (@support-2xl-viewport) {
  .id7-left-border, .id7-right-border {
    .make-large-screen-border-image-sizes(@max-container-width: @container-xl-desktop);
  }
}
.make-border-images() when (@support-xl-viewport) and (@support-2xl-viewport) {
  .id7-left-border, .id7-right-border {
    .make-large-screen-border-image-sizes(@max-container-width: @container-2xl-desktop);
  }
}
.make-border-images();
