.id7-main-content-area {
  background: white;
  // word-break: break-word; - see ID-323 before re-adding

  // Only do this on small screens to avoid strange mobile behaviour where we can see white to the right of the nav
  // We don't want to do it generally as having scrollbars on the main content element are bad
  @media (max-width: (@grid-float-breakpoint - 1)) {
    overflow-x: auto;
  }

  > header {
    .container((@grid-gutter-width * 2));

    // Add the logo to the right hand side when printing
    .id7-horizontal-divider {
      .visible-print-block;
    }

    @media print {
      > .id7-page-title {
        padding-right: (@id7-print-logo-width + @grid-gutter-width);
      }
    }
  }

  > .id7-main-content {
    .container((@grid-gutter-width * 2));
    padding: @grid-gutter-width;
  }
}

.id6-legacy() when not (@id7-ignore-id6-legacy) {
  @import "main-content/id6-legacy.less";
}

@import "main-content/box-styles.less";
@import "main-content/max-widths.less";
@import "main-content/tables.less";
.id6-legacy();
@import "main-content/divider.less";
@import "main-content/margins.less";
@import "main-content/container-breakout.less";
