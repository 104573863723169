.floating-label {
  position: relative;
  display: flex;
  flex-flow: column-reverse;
  label, input {
    transition: opacity 0.2s;
  }

  label {
    position: absolute;
    left: 5px;
    top: -26px;
    visibility: visible;
    opacity: 1;
  }

  input:placeholder-shown ~ label {
    transform-origin: left bottom;
    transform: translate(0, 1.5rem) scale(1.5);
    visibility: hidden;
    opacity: 0;
  }
  
  @media (max-width: @screen-sm-min) {
    input:placeholder-shown ~ label {
      // SBTWO-10013 Do no transformation on Search Warwick input label so it will not overflow
      transform: none;
    }

    label {
      visibility: hidden;
      opacity: 0;
    }
  }
}
