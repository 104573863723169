.id7-horizontal-divider {
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: @grid-gutter-width;
  right: @grid-gutter-width;

  > svg {
    float: right;
  }

  + * {
    margin-top: @grid-gutter-width;
  }
}