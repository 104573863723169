.id7-outline() {
  outline: 3px solid @focus-outline;
  color: #0b0c0c !important;
  background-color: @focus-outline !important;
  box-shadow: 0 -2px @focus-outline, 0 6px #0b0c0c;
  text-decoration: none !important;
  transition-duration: 0s;
}

// This overrides Bootstrap's own tab-focus mixin which is how it gets applied to most links
.tab-focus() {
  outline-offset: 0;
  .id7-outline();
}

a:focus .id7-notifications-badge .counter-value {
  color: white;
}

.open > a:focus, .dropdown-menu > .active > a:focus, .dropdown-toggle:focus, [role="button"]:focus {
  .id7-outline();
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  box-shadow: none;
}

input[type=checkbox]:focus, input[type=radio]:focus {
  box-shadow: none;
}
