.id7-masthead {
  .container-fluid((@grid-gutter-width * 2));

  .id7-masthead-contents {
    .make-row();
  }

  .id7-logo-column {
    .make-sm-column(8);

    float: left;
    width: @id7-masthead-logo-width-xs;

    @media (min-width: @grid-float-breakpoint) {
      margin-bottom: 2px;
    }
  }

  .id7-search-column {
    .make-sm-column(4);

    float: right;
    margin-left: (-@id7-masthead-logo-width-xs);
    max-width: 50%;
    padding-left: 0;

    @media (min-width: @screen-sm-min) {
      padding-left: @grid-gutter-width;
      margin-left: 0;
    }

    @media (max-width: @screen-sm-max) {
      .id7-search-box-container .input-lg {
        // Also make the placeholder text smaller on sm

        &::-moz-placeholder { // Firefox
          font-size: 1.15rem;
          line-height: 2.4em;
        }
        &:-ms-input-placeholder { // Internet Explorer 10+
          font-size: 1.15rem;
          line-height: 2.4em;
        }
        &::-webkit-input-placeholder {  // Safari and Chrome
          font-size: 1.15rem;
          line-height: 2.4em;
        }
      }
    }
  }

  .id7-logo-row {
    .make-row();
    height: @id7-masthead-logo-height-xs;
  }

  // White bleed over the masthead image.
  background-image: url("@{id7-image-root-dir}/masthead-logo-bleed-xs.svg");
  @media (min-width: @screen-sm-min) {
    background-image: url("@{id7-image-root-dir}/masthead-logo-bleed-lg.svg");
  }
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto 300px;

  @media (min-width: @grid-float-breakpoint) {
    .id7-logo-row {
      height: @id7-masthead-logo-height-sm;
      > .id7-logo {
        width: @id7-masthead-logo-width-sm;
        float: left;
      }
    }
  }

  .id7-logo a {
    display: block;
    height: @id7-masthead-logo-height-xs;
    width: 100%;
    text-align: center;

    @media (min-width: @grid-float-breakpoint) {
      height: @id7-masthead-logo-height-sm;
    }

    img {
      .opacity(0);
      height: 100%; // BZ-687311
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  .id7-header-text {
    .header-colour(@text-color);
    .link-colour(@text-color);
    .link-decoration(underline);

    @media (min-width: @grid-float-breakpoint) {
      .link-decoration(none);
    }

    padding: 0 (@grid-gutter-width / 2);
    position: relative;

    h1 {
      font-size: @id7-header-h1-font-size-xs;
      .text-overflow();

      @media (min-width: @grid-float-breakpoint) {
        font-size: @id7-header-h1-font-size-sm;
      }

      line-height: @id7-header-h1-height;
      margin: 0 0 0 -5px;
      padding: @id7-header-h1-padding-vertical 0 @id7-header-h1-padding-vertical 5px;
    }

    transform: translate3d(0, 0, 0); // ID-188

    &.affix {
      position: fixed;
      top: 0;
      margin: 0 (-@grid-gutter-width / 2);
      padding: 0 @grid-gutter-width;
      width: var(--w-sys-containerWidth);
      z-index: 10;
      background-color: white;

      &.headroom {
        transition: transform 100ms linear;
      }
      &.headroom--pinned {
        transform: translateY(0%);
        transform: translate3d(0, 0, 0) translateY(0%);
      }
      &.headroom--unpinned {
        transform: translateY(-100%);
        transform: translate3d(0, 0, 0) translateY(-100%);
      }
    }

    .id7-parent-site-link {
      color: @gray-light;

      &::after {
        content: '';
        @media(max-width: @screen-xs-max) {
          .triangle(right, 12px, 9px, @gray-light);
          margin-left: 4px;
          display: inline-block;
          position: relative;
        }
        @media(min-width: @screen-sm-min) {
          .triangle(right, 14px, 10px, @gray-light);
          top: -2px;
          margin-left: 8px;
          display: inline-block;
          position: relative;
        }
      }
    }
  }

  .id7-logo-row nav {
    display: none;

    @media (min-width: @grid-float-breakpoint) {
      display: block;
    }

    margin-left: @id7-masthead-logo-width-sm;

    &:not(:focus-within) {
      .opacity(0);
    }

    .transition(opacity 0.15s linear);
    .small();

    @line-height-small-computed: (@font-size-small-px * @line-height-small); // ~17px
    margin-top: ((@id7-masthead-logo-height-sm * 5/6) - (@line-height-small-computed / 2));

    ul {
      .horizontal-utility-links();
    }

    .link-colour(@gray-dark);
  }

  .id7-logo-row:hover nav { .opacity(1); }

  .id7-search-box-container {
    margin-top: @id7-masthead-search-offset-xs;

    @media (min-width: @grid-float-breakpoint) {
      margin-top: (@id7-masthead-search-offset-sm + (@line-height-computed / 2));
    }
  }
}

// Overrides for ID7.1, July 2024, ID-416
body.id7-point-1 .id7-utility-masthead .id7-masthead {
  background-image: none;

  .id7-logo-column {
    padding-left: 0;

    .id7-logo > a > img {
      opacity: 1;
    }
  }
}

// Unbranded sites don't get the logo in the masthead
.id7-non-branded {
  .id7-masthead {
    background: white !important;

    @media (min-width: @screen-xs-max) {
      height: auto;
    }
  }

  .id7-logo-column,
  .id7-utility-masthead::after {
    content: none;
    display: none;
  }

  .id7-search-box-container {
    margin-top: 4.3rem !important;
  }

  @media print {
    .id7-main-content-area > header .id7-horizontal-divider {
      display: none !important; // ID-189
    }
  }
}
