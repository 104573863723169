.equal-height-xs {
  .equal-height-columns();
}

@media (min-width: @screen-sm-min) {
  // Legacy, use equal-height-sm
  .equal-height {
    .equal-height-columns();
  }

  .equal-height-sm {
    .equal-height-columns();
  }
}

@media (min-width: @screen-md-min) {
  .equal-height-md {
    .equal-height-columns();
  }
}

@media (min-width: @screen-lg-min) {
  .equal-height-lg {
    .equal-height-columns();
  }
}

.pull-xs-left {
  float: left;
}
.pull-xs-right {
  float: right;
}

@media (min-width: @screen-sm-min) {
  .pull-sm-left {
    float: left;
  }
  .pull-sm-right {
    float: right;
  }
}

@media (min-width: @screen-md-min) {
  .pull-md-left {
    float: left;
  }
  .pull-md-right {
    float: right;
  }
}

@media (min-width: @screen-lg-min) {
  .pull-lg-left {
    float: left;
  }
  .pull-lg-right {
    float: right;
  }
}

.flush-left {
  margin-left: (-@grid-gutter-width);
}

.flush-right {
  margin-right: (-@grid-gutter-width);
}

.full-width {
  .flush-left;
  .flush-right;
}

.flush-top {
  margin-top: (-@grid-gutter-width);
}

.flush-bottom {
  margin-bottom: (-@grid-gutter-width);
}