.id7-search {
  font-size: @font-size-base;

  .id7-search-box-container {
    position: relative;
  }

  .input-lg {
    padding-right: (@padding-large-horizontal + @id7-masthead-search-button-width);
    .placeholder(#707070);
    border-color: #707070;
  }

  .form-group {
    margin-bottom: 0;
  }

  .search-button {
    position: absolute;
    top: 9px;
    right: 9px;

    color: @gray-light;
    cursor: pointer;
    .transition(color .15s linear);
  }

  .typeahead:hover + .fas {
    color: black;
  }

  .typeahead {
    width: 100%;
  }

  .typeahead.dropdown-menu {
    margin-top: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .box-shadow(0 5px 10px rgba(0,0,0,.2));

    left: auto !important;
    right: 0px !important;
    min-width: 100%;
    width: (320px - (@grid-gutter-width * 2));

    @media (min-width: @screen-md-min) {
      left: 0px !important;
      right: auto !important;
      width: 100%;
    }
  }

  .typeahead.dropdown-menu {
    padding: 0;

    > li > a {
      padding: 8px 20px;
      line-height: 1.8rem;
      white-space: normal;
    }

    > li + li {
      border-top: 1px solid #ccc;
    }

    .go-path {
      font-size: 20px;
      font-weight: bold;
    }

    .go-description {
      margin: 0;
    }
  }

  .search-button {
    appearance: none;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
  }

  .search-button:focus {
    .tab-focus();
    .fas {
      color: #204f79;
    }
  }
}
