.id7-notifications-badge {
  font-size: 0.8em;
  opacity: 0.8;
  margin-left: 2px;

  + .caret {
    margin-left: 2px;
  }

  .counter-value {
    font-size: 1.25em;
  }

  .counter-value:not(.fa-exclamation-triangle):not(.fa-spinner) {
    font-family: @font-family-sans-serif;
  }

  &.animating {
    opacity: 1;
    animation-name: flash;
    animation-duration: 5s;
    animation-delay: 5s;
    animation-iteration-count: 5;
  }
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation: slideInDown 0.3s;
}

// Animate.css
@keyframes flash {
  from, 10%, to {
    opacity: 1;
  }

  5%, 15% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 1; // no-op
  }
}
