.container(@gutter) {
  .container-fixed(@gutter);

  @media screen {
    width: var(--w-sys-containerWidth);
  }
}

.container-fluid(@gutter) {
  .container-fixed(@gutter);
}

// Fixes for where Bootstrap was designed badly
.popover-content {
  padding: @popover-padding-vertical @popover-padding-horizontal;
}

.btn {
  border-radius: @button-border-radius-base;
}

.btn-lg {
  border-radius: @button-border-radius-large;
}

.btn-sm {
  border-radius: @button-border-radius-small;
}

.btn-xs {
  border-radius: @button-border-radius-small;
}

.btn-group-vertical > .btn {
  &:first-child:not(:last-child) {
    border-top-right-radius: @button-border-radius-base;
    border-top-left-radius: @button-border-radius-base;
  }
  &:last-child:not(:first-child) {
    border-bottom-left-radius: @button-border-radius-base;
    border-bottom-right-radius: @button-border-radius-base;
  }
}

.pager li {
  > span, > a {
    padding: @padding-base-vertical @padding-base-horizontal;
    border-radius: @button-border-radius-large;
  }
}

// Matches .pagination > li > a
.pager > li > a {
  text-decoration: none;
}

a.list-group-item {
  text-decoration: none;
}

.dropdown-menu li {
  margin-bottom: 0;
}

// Print styling fixes
@media print {
  // Don't expand protocol links for tel: or mailto:
  a[href^="mailto:"]:after,
  a[href^="tel:"]:after {
    content: "";
  }

  // ID-152 re-instate zebra striping on table-striped
  .table-striped {
    > tbody > tr:nth-of-type(odd) {
      background-color: @table-bg-accent !important;

      > td {
        background-color: transparent !important;
      }
    }
  }
}

// ID-115
html.no-touch .modal-open {
  overflow: initial;
  padding-right: 0 !important;
}

.label-default {
  // ID-179 Change the default styling to make it look like a button???
  color: @label-default-text;

  a& {
    &:hover,
    &:focus {
      color: @label-default-text;
    }
  }

  border: 1px solid @label-default-border;
}

.nav-tabs {
  > li {
    > a {
      text-decoration: none;
      margin-right: -1px;
      background-color: @nav-tabs-link-bg;
      color: @nav-tabs-link-color;
      border-color: @nav-tabs-link-hover-border-color;

      &:hover,
      &:focus {
        background-color: @nav-tabs-link-hover-bg;
      }
    }

    &.active {
      background: @nav-tabs-active-bg;

      > a {
        margin-right: -1px;
      }
    }

    &.disabled {
      > a {
        &:hover,
        &:focus {
          background-color: @nav-tabs-link-bg;
        }
      }
    }
  }
}

// ID-184/ID-217 Correct vertical alignment of label and control
.checkbox, .checkbox-inline, .radio-inline, .radio {
  input[type="checkbox"], input[type="radio"] {
    margin-top: 6px;
  }
}

// ID-218 Supplementary label styling for radios/checkboxes
label.checkbox, label.radio, label.checkbox-inline, label.radio-inline {
  font-weight: 400;
}

// ID-214
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    #gradient > .vertical(#d0d0d0, darken(#d0d0d0, 5%));
    color: #333; // ID-223
  }
}

.alert-danger {
  .link-colour(inherit);
}

// iPad dropdown caret not visible
.caret {
  border-top: 4px solid;
}

// ID-256 Achieve greater contrast by having labels on lighter backgrounds
.label-primary {
  .lighter-label-variant(lighten(@brand-primary, 50%); darken(@brand-primary, 20%));
}
.label-success {
  .lighter-label-variant(@state-success-bg; darken(@state-success-text, 10%));
}
.label-info {
  .lighter-label-variant(@state-info-bg; darken(@state-info-text, 10%));
}
.label-warning {
  .lighter-label-variant(@state-warning-bg; darken(@state-warning-text, 10%));
}
.label-danger {
  .lighter-label-variant(@state-danger-bg; darken(@state-danger-text, 10%));
}

// Greater alert contrast
.alert-success {
  .lighter-alert-variant(@state-success-bg; darken(@state-success-text, 20%); darken(@state-success-text, 10%));
}
.alert-info {
  .lighter-alert-variant(@state-info-bg; darken(@state-info-text, 20%); darken(@state-info-text, 10%));
}
.alert-warning {
  .lighter-alert-variant(@state-warning-bg; darken(@state-warning-text, 20%); darken(@state-warning-text, 10%));
}
.alert-danger {
  .lighter-alert-variant(@state-danger-bg; darken(@state-danger-text, 20%); darken(@state-danger-text, 10%));
}

// FA close icons
.popover .close i {
  font-size: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.75rem;
}

.popover button.close {
  opacity: 1;
}

button.close {
  opacity: 1;
  color: @text-muted;
}

// Contrast fixes ID-347

.help-block {
  color: darken(@text-muted, 10%);
}

/* Extra font-size fixes ID-356 */

pre {
  font-size: 1.3rem;
}

.panel-title {
  font-size: 1.6rem;
}
