.id7-container-breakout {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

// Add more responsive embeds while we're here
.embed-responsive-5by4 {
  padding-bottom: 80%;
}

.embed-responsive-3by2 {
  padding-bottom: 66.67%;
}

.embed-responsive-3by1 {
  padding-bottom: 33.33%;
}
