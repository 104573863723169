@borderless-rules: {
  @media (min-width: @grid-float-breakpoint) {
    .id7-left-border, .id7-right-border {
      display: none;
      background-image: none !important;
    }
  }

  // Escape the fixed width container
  @media (min-width: @grid-float-breakpoint) {
    // Cancel existing containers
    .id7-fixed-width-container {
      width: auto;
    }
  }

  // Put contents back in a container
  .id7-utility-bar,
  .id7-masthead .id7-masthead-contents,
  .id7-navigation .navbar .navbar-nav,
  .id7-site-footer .id7-site-footer-content, .id7-app-footer .id7-app-footer-content {
    .container(@grid-gutter-width);
  }

  .id7-utility-bar {
    padding: 0 @grid-gutter-width;
  }

  .id7-navigation.affix {
    width: 100%;
  }

  @media (max-width: @screen-xs-max) {
    .id7-masthead .id7-masthead-contents {
      padding: 0;
      margin: 0 -(@grid-gutter-width / 2);
    }
  }

  // We don't support the right-floated dropdown nav
  .id7-navigation .navbar-nav {
    float: none;
  }

  // Make sure the affixed header text is on a white background
  .id7-masthead .id7-header-text.affix {
    &::before, &::after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      background: white;
    }

    &::before {
      left: -99%;
    }

    &::after {
      right: -99%;
    }
  }

  @media (min-width: @grid-float-breakpoint) {
    .id7-masthead {
      background: none;
      padding: 0;
    }

    .id7-utility-masthead {
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0px;
        bottom: 0px;
      }
    }
  }

  // Apply white bleed as a ::before pseudo-element.
  // Use the same massive bleed SVG for all breakpoints.
  @media (min-width: @screen-sm-min) {
    .id7-utility-masthead {
      @svg-width: 3000px;
      &::before {
        .svg-background('@{id7-image-root-dir}/masthead-logo-bleed-borderless-2xl', 0 @id7-masthead-bleed-height);
        background-size: @svg-width 300px;
        width: @svg-width;

        left: calc((100vw - var(--w-sys-containerWidth)) / 2 - 572px);
      }

      &::after {
        left: calc((50% - (@container-2xl / 2)) + @grid-gutter-width);
      }
    }
  }
}


// Global borderless using @borderless-container
& when (@borderless-container) {
  html {
    overflow-x: hidden; // Avoid horizontal scrollbar
  }
  @borderless-rules();
}

// Built-in conditional borderless by applying the body style
// (Only include this class when not using the global borderless)
html.id7-borderless when not (@borderless-rules) {
  overflow-x: hidden; // Avoid horizontal scrollbar
  @borderless-rules();
}
