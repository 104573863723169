.horizontal-utility-links(@spacing: 0.2em, @separator-colour: @gray-light) {
  padding-left: 0;
  list-style: none;
  margin: 0;

  > li {
    display: inline-block;
    padding: 0;
    margin: 0;

    &:before {
      border-right: 1px solid @separator-colour;
      content: '';
      height: 1em;
      opacity: 0.5;
      display: inline-block;
      margin-left: (@spacing * 0.8);
      margin-right: (@spacing * 1.8);
      margin-bottom: -0.15em;

    }

    &:first-child:before {
      content: none;
      display: none;
    }
  }

  // ID-60 Don't expand utility bar links in print view
  @media print {
    a[href]:after {
      content: "";
    }
  }
}
