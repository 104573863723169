.id7-utility-bar {
  .container-fluid((@grid-gutter-width * 2));
  position: relative;
  .small();
  white-space: nowrap;

  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  height: @id7-masthead-bleed-height;
  line-height: @id7-masthead-bleed-height;

  > ul {
    .pull-right();
  }

  .link-colour(white);

  > ul {
    .horizontal-utility-links(0.4em, @gray-lighter);
  }

  .caret { margin-left: 5px; }

  iframe[seamless] {
    background-color: transparent;
    padding: 0px;
    overflow: hidden;
  }

  .dropdown-menu {
    margin-top: 0;
    padding: 5px 0;
    font-size: inherit;

    > li {
      margin: 0;

      > a {
        padding: 1px 20px;

        &,
        &:link,
        &:visited,
        &:hover {
          color: @text-color;
          text-decoration: none;
        }
      }
    }

    > li.divider {
      margin: 5px 0;
    }
  }

  .popover.my-warwick:not(.account-information) {
    width: @id7-mw-popover-width;
    max-height: @id7-mw-popover-height;
    height: 80vh;
    padding: 0;
  }

  .popover.my-warwick.loaded:not(.account-information) {
    .arrow {
      border-bottom-color: #8C6E96;
    }

    .arrow:after {
      border-bottom-color: #8C6E96;
    }
  }

  .my-warwick-popover-theme(@n, @colour) {
    .popover.my-warwick.loaded {
      &.theme-transparent-@{n}, &.theme-transparent-@{n}--high-contrast {
        &:not(.account-information) {
          .arrow {
            border-bottom-color: @colour;
          }

          .arrow:after {
            border-bottom-color: @colour;
          }
        }
      }
    }
  }

  .my-warwick-popover-theme(1, #8C6E96);
  .my-warwick-popover-theme(2, #AC554E);
  .my-warwick-popover-theme(3, #338888);
  .my-warwick-popover-theme(4, #2F6B88);
  .my-warwick-popover-theme(5, #A35138);

  .popover.account-information, .popover.my-warwick {
    text-shadow: none;
    max-width: 100%;
    display: flex !important;

    .arrow, .arrow:after {
      transition: 0.3s border ease-in-out;
    }

    .popover-inner, .popover-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0;
    }

    .popover-content {
      .account-info {
        iframe {
          height: 100%;
          width: 100%;
        }
        flex: 1;
      }

      .actions {
        flex: 0 0 auto;
      }
    }
  }

  .actions {
    .link-colour(@gray-dark);

    a:hover, a:focus {
      text-decoration: none;
    }

    background: @id7-account-popover-actions-bg;
    border-top: 1px solid @id7-account-popover-actions-border;

    .btn-group-justified {
      border-spacing: (@line-height-computed / 2);
    }
  }

  .popover.account-information {
    width: @id7-account-popover-width;
    height: auto;

    .popover-content .account-info {
      padding: 15px 19px;
    }

    .popover-content {
      .account-info {
        iframe {
          height: 80px;
        }
      }
    }

  }
}

// Overrides for ID7.1, January 2024, ID-416
body.id7-point-1 .id7-utility-masthead  .id7-utility-bar {
  .link-colour(@text-color);

  > ul {
    .horizontal-utility-links(0.4em, @text-color);
  }

  a > .fa-stack.id7-notifications-badge {
    > i { color: #dddddd; }
  }
}
