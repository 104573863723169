@nav-triangle-width: 13px;
@nav-triangle-height: 7px;

.id7-navigation {
  .link-decoration(@hover: none);
  position: relative;
  z-index: 3;

  .navbar {
    min-height: 0;
    border-width: 0px;
  }

  transform: translate3d(0, 0, 0); // ID-188

  &.affix {
    position: fixed;
    top: @id7-affixed-header-height;
    width: var(--w-sys-containerWidth);
    z-index: 20;

    &.headroom {
      transition: transform 100ms linear;
    }
    &.headroom--pinned {
      transform: translateY(0%);
      transform: translate3d(0, 0, 0) translateY(0%);
    }
    &.headroom--unpinned {
      transform: translateY(-100%) translateY(-@id7-affixed-header-height);
      transform: translate3d(0, 0, 0) translateY(-100%) translateY(-@id7-affixed-header-height);
    }
  }

  .dropdown-menu {
    border: 0;
  }

  @media (min-width: @grid-float-breakpoint) {
    .navbar-nav .dropdown .dropdown-menu {
      display: block;
      visibility:hidden;
      .opacity(0);
      .transition-delay(250ms);
      .transition(~"opacity 0.15s linear");
    }
    .navbar-nav .dropdown:hover .dropdown-menu, .navbar-nav .dropdown.open .dropdown-menu {
      height: auto;
      visibility:visible;
      .opacity(1);
    }
  }

  .navbar-nav {
    margin: 0 10px;
    float: left;

    &.navbar-right {
      float: right !important;

      .dropdown-menu {
        .dropdown-menu-right();

        .dropdown-menu {
          display: none;
        }
      }

      // hide active arrow
      .dropdown.active::before {
        display: none;
      }
    }

    > li {
      // Fiddle with the padding to expand the <a> all the way out
      padding: @nav-link-padding;
      padding-top: @navbar-padding-vertical;
      padding-bottom: @navbar-padding-vertical;

      position: relative;
      display: flex;
      margin: 0;
      float: left;
      cursor: pointer;
      .transition(~"background-color .15s linear, color .15s linear");

      &:hover + a {
        text-decoration: underline;
      }

      > a {
        padding: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      &:nth-child(2) {
        @media (min-width: @screen-sm-min) {
          margin-left: 1px;
        }
      }

      // Arrow background for first breadcrumb in .navbar-secondary
      &.nav-breadcrumb:first-child {
        @media (min-width: @screen-sm-min) {
          display: none;
        }

        margin-right: 17px;
        margin-left: (@grid-gutter-width / -2);
        padding-left: @grid-gutter-width;

        &::after {
          top: 0;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          right: -17px;
          border-style: solid;
          border-width: 17px 0 17px 17px;
          z-index: 10;
        }
      }

      // For breadcrumbs in .navbar-secondary
      &.nav-breadcrumb:not(:first-child):not(:last-child):not(.dropdown) {
        margin-right: 0.5em;

        &::after {
          .fa();
          font-size: 0.7em;
          content: @fa-var-play;
          position: absolute;
          left: 101%;
          top: 14px;
          line-height: 10px;
          .opacity(1);
        }
      }

      // bar between breadcrumbs and siblings (if present)
      // bar between siblings
      &:last-child + li::before,
      &:not(.nav-breadcrumb):not(:last-child)::after {
        border-right: 1px solid currentColor;
        content: ' ';
        height: 1em;
        opacity: 0.5;
        display: inline-block;
        position: absolute;
        left: 100%;
        top: calc(50% - 0.535em);
      }

      // bar between breadcrumbs and siblings (if present)
      &:last-child + li::before {
        left: 0%;
        margin-left: -3px;
      }

      // bar between siblings
      &:not(.nav-breadcrumb):not(:last-child)::after {
        //left: 100%;
        //margin-left: -2px;
      }

      &.active {
        font-weight: bold;

        // active section triangle indicator
        &::before {
          content: " ";
          position: absolute;
          bottom: 0px;
          left: 50%;
          z-index: 2;
          margin-left: (-@nav-triangle-width / 2);

          .triangle(up, @nav-triangle-width, @nav-triangle-height, white);
        }

        // hide the triangle on hover if there's a menu
        &.dropdown:hover::before,
        &.dropdown:active::before {
          display: none;
        }
      }


      &.dropdown {

        & > a::after {
          content: ' ';
          .caret();
        }

        // If we provide a real DOM caret, we add this class to hide the pseudo element
        & > a.has-button-caret::after {
          display: none;
        }

        & > a > span.caret {
          display: none;
        }

        @media (hover: none) {
          & > a::after {
            content: '';
            display: none;
          }
        }

      }
    }

    ul.dropdown-menu > li.active {
      // highlighting in 'more' dropdowns
      > a {
        font-weight: bold;
      }

      // active section triangle indicator
      &::after {
        content: ' ';
        position: absolute;
        z-index: 2;
        margin-top: (-@font-size-base-px + 1 - @nav-triangle-width/2);

        .triangle(right, @nav-triangle-width, @nav-triangle-height, white);
      }
    }
  }

  .navbar-secondary, .navbar-tertiary {
    .navbar-nav > li:not(.nav-breadcrumb) {
      font-size: (@font-size-base - 0.1rem);
    }
  }

  .navbar-secondary > li:first-child::after {
    .transition(~"background-color .15s linear, color .15s linear")
  }

  &.affix + .id7-navigation-marker {
    background: white;
    display: block;
  }

  .discreet {
    .opacity(0.4);
  }

  .navbar-primary.navbar-wrapped > .navbar-nav > li::before {
    display: none;
  }

  button.nav-dropdown-button {
    background: none;
    border: none;
    margin: 0 0 0 4px;
    padding: 0;

    &:focus {
      .tab-focus();
    }
  }


}

.important-no-transition * {
  .transition(~"none") !important
}

.id7-navigation-marker { display: none; }
