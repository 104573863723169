footer {
  .id7-site-footer .id7-site-footer-content, .id7-app-footer .id7-app-footer-content {
    .container-fluid((@grid-gutter-width * 2));
    position: relative;
    padding-top: @grid-gutter-width;
  }

  .id7-app-footer {
    .small();

    .page-footer-elements {
      margin-top: 0;

      ul {
        margin: 0;
        padding-left: 0;
        list-style: none;
      }
    }

    .id7-footer-utility {
      margin-bottom: @grid-gutter-width;

      ul {
        .horizontal-utility-links();
      }
    }
  }

  // Footer styling elements - logo bleed and/or divider
  .id7-site-footer, .id7-app-footer {
    @media screen {
      .id7-logo-bleed {
        .logo-as-css(auto, (@grid-gutter-width * 2), white);
        position: absolute;
        top: 0px;
        right: 10%;

        + * {
          margin-top: @grid-gutter-width;
        }
      }
    }
  }

  @media print {
    &.id7-footer-coloured .id7-site-footer::before {
      border-top: 1px solid @text-color;
      display: block;
      margin-bottom: @grid-gutter-width;
    }

    .id7-site-footer::after {
      border-bottom: 1px solid @text-color;
      display: block;
      margin-top: @grid-gutter-width;
    }

    .id7-horizontal-divider .divider {
      stroke: @text-color !important;
    }
  }
}

// ID-428 Remove W cutout in ID7.1
body.id7-point-1 footer {
  .id7-site-footer, .id7-app-footer {
    @media screen {
      .id7-logo-bleed {
        display: none;
      }
    }
  }
}

.id7-non-branded {
  .id7-page-footer {
    .id7-site-footer, .id7-app-footer {
      .id7-logo-bleed {
        display: none;

        + * {
          margin-top: 0;
        }
      }
    }
  }
}
