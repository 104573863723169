// Support wider viewports than standard
.id7-main-content-area {
  > header, > .id7-main-content {
    .container((@grid-gutter-width * 2));
  }
}

// col-xl-* and col-2xl-* classes
.make-xl-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-right: (@gutter / 2);
  padding-left: (@gutter / 2);

  @media (min-width: @screen-xl-min) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}
.make-xl-column-offset(@columns) {
  @media (min-width: @screen-xl-min) {
    margin-left: percentage((@columns / @grid-columns));
  }
}
.make-xl-column-push(@columns) {
  @media (min-width: @screen-xl-min) {
    left: percentage((@columns / @grid-columns));
  }
}
.make-xl-column-pull(@columns) {
  @media (min-width: @screen-xl-min) {
    right: percentage((@columns / @grid-columns));
  }
}

.make-2xl-column(@columns; @gutter: @grid-gutter-width) {
  position: relative;
  min-height: 1px;
  padding-right: (@gutter / 2);
  padding-left: (@gutter / 2);

  @media (min-width: @screen-2xl-min) {
    float: left;
    width: percentage((@columns / @grid-columns));
  }
}
.make-2xl-column-offset(@columns) {
  @media (min-width: @screen-2xl-min) {
    margin-left: percentage((@columns / @grid-columns));
  }
}
.make-2xl-column-push(@columns) {
  @media (min-width: @screen-2xl-min) {
    left: percentage((@columns / @grid-columns));
  }
}
.make-2xl-column-pull(@columns) {
  @media (min-width: @screen-2xl-min) {
    right: percentage((@columns / @grid-columns));
  }
}

// Extra wide grid column styles for xl and 2xl
.make-wide-grid-columns() when (@support-xl-viewport) {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) { // initial
    @item: ~".col-xl-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-xl-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-right: floor((@grid-gutter-width / 2));
      padding-left: ceil((@grid-gutter-width / 2));
    }
  }
  .col(1); // kickstart it
}
.make-wide-grid-columns() when (@support-xl-viewport) and (@support-2xl-viewport) {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) { // initial
    @item: ~".col-2xl-@{index}";
    .col((@index + 1), @item);
  }
  .col(@index, @list) when (@index =< @grid-columns) { // general; "=<" isn't a typo
    @item: ~".col-2xl-@{index}";
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when (@index > @grid-columns) { // terminal
    @{list} {
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-right: floor((@grid-gutter-width / 2));
      padding-left: ceil((@grid-gutter-width / 2));
    }
  }
  .col(1); // kickstart it
}

.make-wide-grid-columns();

.make-wide-grid() when (@support-xl-viewport) {
  @media (min-width: @screen-xl-min) {
    .make-grid(xl);
  }
}
.make-wide-grid() when (@support-xl-viewport) and (@support-2xl-viewport) {
  @media (min-width: @screen-2xl-min) {
    .make-grid(2xl);
  }
}
.make-wide-grid();
