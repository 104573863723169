html {
  font-size: 0.625rem;
}

.id7-main-content-area {
  font-weight: @main-font-weight;
  font-size: (@main-font-size * @font-scale-xs);
  font-weight: 400;

  @media (min-width: @grid-float-breakpoint) {
    font-size: @main-font-size;
  }

  // Only add the right padding on a screen (to avoid it going on print)
  @media screen and (min-width: @grid-float-breakpoint) {
    .id7-main-content > .layout > .column-1 > .column-1-content > p {
      padding-right: 4rem;
    }
  }
}
