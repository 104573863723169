// For floating/padding convenience (ID-281)

.margin-top {
  margin-top: @grid-gutter-width;
}

.margin-right {
  margin-right: @grid-gutter-width
}

.margin-bottom {
  margin-bottom: @grid-gutter-width;
}

.margin-left {
  margin-left: @grid-gutter-width;
}

.margin-top-left {
  margin-top: @grid-gutter-width;
  margin-left: @grid-gutter-width;

}

.margin-top-right {
  margin-top: @grid-gutter-width;
  margin-right: @grid-gutter-width;
}

.margin-bottom-left {
  margin-bottom: @grid-gutter-width;
  margin-left: @grid-gutter-width;
}

.margin-bottom-right {
  margin-bottom: @grid-gutter-width;
  margin-right: @grid-gutter-width;
}
